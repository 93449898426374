.no-wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transition {
  @include transition(all 0.4s);
}

.vertical-divider {
  border-right: 1px solid var(--p-color-border);
  height: 100%;

  @media (max-width: 48em) {
    border-right: none;
  }
}